<template>
	<div>
		<div
			style="width: 94%;height: 170px;background: url(http://zgkzs.oss-cn-beijing.aliyuncs.com/public/64bd2265d0968.jpg) no-repeat;border-radius: 14px;position: absolute;top: 12px;left: 10px;z-index:3;display: flex;background-size: cover;">
			<div class="d-flex a-center position-absolute left-0 right-0" style="bottom: 90px;">
				<!-- 用户头像 -->
				<el-avatar
					:src="user.avatar ? user.avatar : 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"
					class="ml-3"></el-avatar>
				<div class="ml-3">
					<div style="color: #fff;font-size: 12px;">{{user.username}}</div>
					<div style="background-color: #31302e;border-radius: 30px;width: 88px;height: 22px;"
						class="d-flex a-center j-center">
						<div style="color: #e3b574;font-size: 10px;">基础会员</div>
					</div>
				</div>
			</div>
			<div class="d-flex position-absolute " style="top: 204rpx;right: 0;left: 0;bottom: 16px;">
				<div style="width: 50%;text-align: center;">
					<div style="color: #fff;font-weight: 800;font-size: 16px;">{{jf}}</div>
					<div style="display: block;color: #fff;font-size: 12px;">剩余次数</div>
				</div>
				<div style="width: 50%;text-align: center;">
					<div style="color: #fff;font-weight: 800;font-size: 16px;">暂未开通</div>
					<div style="display: block;color: #fff;font-size: 12px;">会员有效期</div>
				</div>
			</div>
		</div>
		
		<div>
			<div
				style="width: 94%;background: url(http://zgkzs.oss-cn-beijing.aliyuncs.com/public/64bd2265d0968.jpg) no-repeat;border-radius: 10px;position: absolute;top: 198px;left: 10px;z-index:3;background-size: cover;">
				<div class="m-2" style="color: #fff;font-size: 16px;font-weight: 800;">次数充值</div>
				<div class="d-flex a-center m-2" :class="dqxz === 0 ? 'fltxa' : 'fltxb'" @click="fgxz(0)">
					<div style="width: 74%;margin-left: 4%;">
						<div style="font-size: 16px;" class="txt">{{jcsz.czaa}}次数</div>
						<div style="font-size: 12px;" class="txt">一次性发放{{jcsz.czaa}}次数，无时间限制</div>
					</div>
					<div class="d-flex a-center j-center" style="width: 22%;">
						<div style="font-size: 12px;font-weight: 800;" class="txt">￥</div>
						<div style="font-size: 20px;font-weight: 800;" class="txt">{{ jcsz.cza }}</div>
					</div>
				</div>
		
				<div class="d-flex a-center m-2" :class="dqxz === 1 ? 'fltxa' : 'fltxb'" @click="fgxz(1)">
					<div style="width: 74%;margin-left: 4%;">
						<div style="font-size: 16px;" class="txt">{{jcsz.czbb}}次数</div>
						<div style="font-size: 12px;" class="txt">一次性发放{{jcsz.czbb}}次数，无时间限制</div>
					</div>
					<div class="d-flex a-center j-center" style="width: 22%;">
						<div style="font-size: 12px;font-weight: 800;" class="txt">￥</div>
						<div style="font-size: 20px;font-weight: 800;" class="txt">{{ jcsz.czb }}</div>
					</div>
				</div>
		
				<div class="d-flex a-center m-2" :class="dqxz === 2 ? 'fltxa' : 'fltxb'" @click="fgxz(2)">
					<div style="width: 74%;margin-left: 4%;">
						<div style="font-size: 16px;" class="txt">{{jcsz.czcc}}次数</div>
						<div style="font-size: 12px;" class="txt">一次性发放{{jcsz.czcc}}次数，无时间限制</div>
					</div>
					<div class="d-flex a-center j-center" style="width: 22%;">
						<div style="font-size: 12px;font-weight: 800;" class="txt">￥</div>
						<div style="font-size: 20px;font-weight: 800;" class="txt">{{ jcsz.czc }}</div>
					</div>
				</div>
		
				<div class="d-flex a-center m-2" :class="dqxz === 3 ? 'fltxa' : 'fltxb'" @click="fgxz(3)">
					<div style="width: 74%;margin-left: 4%;">
						<div style="font-size: 16px;" class="txt">{{jcsz.czdd}}次数</div>
						<div style="font-size: 12px;" class="txt">一次性发放{{jcsz.czdd}}次数，无时间限制</div>
					</div>
					<div class="d-flex a-center j-center" style="width: 22%;">
						<div style="font-size: 12px;font-weight: 800;" class="txt">￥</div>
						<div style="font-size: 20px;font-weight: 800;" class="txt">{{ jcsz.czd }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex position-fixed a-center" style="bottom: 50px;right: 0;left: 0;background: #3c3c3c;height: 50px;">
			<div style="width: 48%;margin-left: 2%;">
				<div style="font-size: 12px;color: #fff;margin-left: 2%;">实付</div>
				<div style="font-size: 18px;color: #fff;line-height: 20px;font-weight: 800;" v-if="dqxz == 0">￥{{jcsz.cza}}
				</div>
				<div style="font-size: 18px;color: #fff;line-height: 20px;font-weight: 800;" v-else-if="dqxz == 1">￥{{jcsz.czb}}
				</div>
				<div style="font-size: 18px;color: #fff;line-height: 20px;font-weight: 800;" v-else-if="dqxz == 2">￥{{jcsz.czc}}
				</div>
				<div style="font-size: 18px;color: #fff;line-height: 20px;font-weight: 800;" v-else-if="dqxz == 3">￥{{jcsz.czd}}
				</div>
			</div>
			<div style="width: 50%;">
				<div class="d-flex a-center j-center"
					style="border-radius: 10px;background-image: linear-gradient(to left, #00f195,#15fff8);width: 90%;height: 36px;margin: 0 auto;"
					@click="ljzf">
					<div style="color: #000;">立即支付</div>
				</div>
			</div>
		</div>
		
		<!-- <div class="coupon-voucher-code-popup" v-if="showVoucherCode">
			<div class="popup-wrapper">
				<div class="popup-content">
					<div class="">
						<div style="text-align: center;color: #fff;font-weight: 800;">请确定购买信息</div>
					</div>
					<div class="mt-2">
						<div style="text-align: center;color: #fff;font-size: 20rpx;" v-if="dqxz == 0">购买产品：{{ jcsz.czaa }}次数</div>
						<div style="text-align: center;color: #fff;font-size: 20rpx;" v-else-if="dqxz == 1">购买产品：{{ jcsz.czbb }}次数</div>
						<div style="text-align: center;color: #fff;font-size: 20rpx;" v-else-if="dqxz == 2">购买产品：{{ jcsz.czcc }}次数</div>
						<div style="text-align: center;color: #fff;font-size: 20rpx;" v-else>购买产品：{{ jcsz.czdd }}次数</div>
						<div style="text-align: center;color: #fff;font-size: 20rpx;">支付金额：</div>
						<div class="d-flex j-center a-center" >
							<div style="color: #fff;font-size: 20rpx;">￥</div>
							<div style="color: #fff;font-size: 70rpx;font-weight: 800;" v-if="dqxz == 0">{{ jcsz.cza }}</div>
							<div style="color: #fff;font-size: 70rpx;font-weight: 800;" v-else-if="dqxz == 1">{{ jcsz.czb }}</div>
							<div style="color: #fff;font-size: 70rpx;font-weight: 800;" v-else-if="dqxz == 2">{{ jcsz.czc }}</div>
							<div style="color: #fff;font-size: 70rpx;font-weight: 800;" v-else>{{ jcsz.czd }}</div>
						</div>
					</div>
					
					<div class="popup-content-bottom-btns">
						<div class="w-50">
							<div class="d-flex a-center j-center" style="border-radius: 40rpx;width: 90%;height: 70rpx;margin: 0 auto;border: 1rpx solid #fff;" @click="ljjf(1)">
								<div style="color: #fff;">取消</div>
							</div>
						</div>
						<div class="w-50">
							<div
								class="d-flex a-center j-center"
								style="border-radius: 40rpx;background-image: linear-gradient(to left, #00f195,#15fff8);width: 90%;height: 70rpx;margin: 0 auto;"
								@click="ljjf(2)"
							>
								<div>确定支付</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
	import { mapState } from 'vuex'; // 引入 全局 公共数据
	export default {
		data() {
			return {
				jf: 0,
				jcsz:{},
				dqxz:0,
				showVoucherCode: false,
				noid:''
			}
		},
		computed: {
			...mapState({
				user: state => state.user.user,
				token: state => state.user.token
			}),
		},
		created() {
			this.myuser();
		},
		methods: {
			myuser() {
				this.axios.get('/admin/myuser/' + this.user.id, { token:true }).then(res => {
					let data = res.data.data
					this.jf = data.jf;
				});
				this.axios.get('/admin/jcsz', { token:true })
					.then(res => {
						this.jcsz = res.data.data;
					});
			},
			fgxz(e){
				this.dqxz = e
			},

			ljzf(){
				let num = 0
				let nums = 0
				if(this.dqxz == 0){
					num = 'cza'
					nums = 'czaa'
				}else if(this.dqxz == 1){
					num = 'czb'
					nums = 'czbb'
				}else if(this.dqxz == 2){
					num = 'czc'
					nums = 'czcc'
				}else if(this.dqxz == 3){
					num = 'czd'
					nums = 'czdd'
				}
				let data = {
					user_id:this.user.id,
					czje:num,
					czjf:nums
				}
				this.axios.post('admin/payment/webpay',data, { token:true })
					.then(res => {
						let data = res.data.data
						this.noid = data.noid
						this.$confirm('<strong><div class="d-flex j-center a-center"><div style="margin: 10px 0;">请用微信扫码支付</div></div><div class="d-flex j-center a-center"><img src="'+data.img+'"></div></div></strong>', '微信扫码支付', {
							confirmButtonText: '查看是否支付',
							cancelButtonText: '取消支付',
							dangerouslyUseHTMLString: true
						}).then(() => {
							this.axios.get('admin/sfzf/'+this.noid, { token:true })
								.then(res => {
									if(res.data.data == 'ok'){
										this.$message({
											type: 'success',
											message: '订单已支付'
										});
										this.axios.get('/admin/myuser/' + this.user.id, { token:true }).then(res => {
											let data = res.data.data
											this.jf = data.jf;
										});
									}else{
										this.$message({
											type: 'success',
											message: res.data.data
										});
									}
								});
						}).catch(() => {
							this.$message({
								type: 'info',
								message: '已取消支付'
							});          
						});
					});
				
			}
		}
	}
</script>

<style>

	.a-center{
		align-items: center;
	}
	.j-center{
		justify-content: center;
	}
	.position-absolute{ position: absolute; }
	.fltxa {
		border-radius: 10px;
		height: 60px;
		border: 1px solid #00f7ff;
		background: rgb(0, 247, 255,0.1);
	}
	.fltxa .txt {
		color: #00f7ff;
	}
	.fltxb {
		border-radius: 10px;
		height: 60px;
		border: 1px solid #ffffff;
		background: rgb(0, 0, 0,0.3);
	}
	.fltxb .txt {
		color: #ffffff;
	}
	
	.coupon-voucher-code-popup {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.6);
		z-index: 9998;
	}
	.popup-wrapper {
		position: fixed;
		width: 520rpx;
		top: 32%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: $font-base;
		padding: 40rpx;
		border-radius: 20rpx;
		background-color: #152525;
		z-index: 9999;
	}
	
	.popup-content-bottom-btns {
		display: flex;
		justify-content: space-evenly;
		padding: 20rpx;
	}
</style>
